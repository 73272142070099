import { APP_DOMAIN, APP_PROTOCOL } from "./api";

export const metadata = {
  name: "tokenstream.fun",
  shortName: "tokenstream.fun",
  description: "Anytime, any chain - stream, transfer and swap your assets from wherever your assets are, at any tine",
  domain: APP_DOMAIN,
  email: "support@intento.zone",
  url: `${APP_PROTOCOL}://${APP_DOMAIN}`,
  github: {
    username: "trstlabs",
    url: "https://github.com/trstlabs/tokenstream-dot-fun",
  },
  twitter: {
    username: "@IntentoZone",
    url: "https://twitter.com/IntentoZone",
  },
  themeColor: "#bce3f5",
};
